import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { css } from '@emotion/react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { isOpen, formatDates } from '../utility';
import { useEmblaCarousel } from 'embla-carousel/react';
import { DateTime } from 'luxon';
import ProgramCard from '../components/program-card';
import Opportunity from '../components/opportunity';
import Close from '../svg/close-circle.svg';

export default function Index({data, setEmbed, setMenuAbsolute, setActivePage, setActiveSubPage, setShowExtra, setShowInfo, setShowLearn, setShowTeachers, setShowStudents, setImagesModal, showImages}) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [subscribeModal, setSubscribeModal] = useState(true);

  useEffect(() => {
    setMenuAbsolute(true);
    setActivePage(null);
    setActiveSubPage(null);
    setShowExtra(false);
    setShowInfo(false);
    setImagesModal(false);
    setShowLearn(false);
    setShowTeachers(false);
    setShowStudents(false);

    if (getWithExpiry('modal-shown') === 'true') {
      setSubscribeModal(false);
    } else {
      setWithExpiry('modal-shown', 'true', 86400000);
    }
  }, []);

  const emblaOptions = {
    draggable: true,
    speed: 7,
    loop: true,
  };
  const [emblaRef, embla] = useEmblaCarousel(emblaOptions);

  useEffect(() => {
    if (embla) {
      embla.on('select', (eventName) => {
        setCurrentSlide(embla.selectedScrollSnap());
      })
    }
  }, [embla])

  function setWithExpiry(key, value, ttl) {
    const now = new Date()

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: now.getTime() + ttl,
    }
    localStorage.setItem(key, JSON.stringify(item))
  }

  function getWithExpiry(key) {
    const itemStr = localStorage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key)
      return null
    }
    return item.value
  }

  return (
    <div>
      {subscribeModal && <div
        css={css`
          position: absolute;
          bottom: var(--gutter);
          right: var(--gutter);
          width: 100%;
          max-width: 50rem;
          font-family: 'ROM Condensed', sans-serif;
          font-weight: bold;
          font-size: 3.5rem;
          line-height: 1;
          color: var(--black);
          background-color: var(--pink);
          padding: var(--smallGutter);
          border-radius: 2.6rem;
          box-shadow: 0px 0px 24px 1px rgba(0, 0, 0, 0.5);
          z-index: 100;

          @media (max-width: 700px) {
            left: var(--gutter);
            width: auto;
            max-width: none;
          }
        `}
      >
        <div
          css={css`
            position: absolute;
            top: calc(-2.5rem + 2rem);
            right: 1.25rem;
            cursor: pointer;

            @media (max-width: 1440px) {
              top: calc(-2.5rem + 1.25rem);
            }

            &:hover {
              svg path {
                fill: var(--20Brown);
              }
            }

            svg {
              width: 2.5rem;

              @media (max-width: 700px) {
                width: 3rem;
              }
            }
          `}
          onClick={() => setSubscribeModal(false)}
        >
          <Close />
        </div>
        <div
          css={css`
            padding-right: var(--smallGutter);
            margin-bottom: var(--smallGutter);
          `}
        >
          {data.homepage.subscribeText}
        </div>
        <form
          css={css`
            position: relative;
            font-family: 'ROM', sans-serif;
            font-size: 2rem;
          `}
          target="_blank"
          action="https://blindside.us2.list-manage.com/subscribe/post?u=9c9ccb60da895b0caf76a5b64&id=68fccc2582"
          method="POST"
        >
          <input
            css={css`
              width: 100%;
              background-color: white;
              padding: 1.4rem 2rem 1.2rem;
              border: 1px solid var(--black);
              border-radius: 2.6rem;
            `}
            type="email"
            name="EMAIL"
            placeholder="hello@example.com"
          />
          <input
            css={css`
              position: absolute;
              top: 1.25rem;
              right: 1rem;
              font-size: 2rem;
              background-color: transparent;
              border: none;
              cursor: pointer;
            `}
            type="submit"
            value="→"
          />
        </form>
      </div>}
      <div
        ref={emblaRef}
        css={css`
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          height: 100vh;
          font-family: 'ROM Condensed', sans-serif;
          font-weight: bold;
          font-size: 6rem;
          line-height: 1;
          color: var(--white);
          text-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
          overflow: hidden;

          @media (max-width: 700px) {
            height: calc(100vh - 60px);
          }

          a {
            color: var(--white);
          }
        `}
      >
        <div
          css={css`
            display: flex;
          `}
        >
          {data.homepage.slides.length > 0 && data.homepage.slides
            .filter((slide, i) => (slide && (slide.featureImage || slide.image)))
            .map((slide, i) => (
              <Link
                to={(slide.featureImage) ? `/program/${slide.slug?.current}` : slide.link}
                css={css`
                  position: relative;
                  flex: 0 0 100%;
                `}
              >
                <GatsbyImage
                  image={(slide.featureImage) ? slide.featureImage.asset.gatsbyImageData : slide.image.asset.gatsbyImageData}
                  alt={(slide.featureImage) ? slide.featureImage.asset.altText : slide.image.asset.altText}
                  objectFit="cover"
                  loading="eager"
                  css={css`
                    height: 100vh;
                  `}
                />
                {slide.featureImage && <div
                  css={css`
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    text-align: center;
                  `}
                >
                  <div
                    css={css`
                      display: grid;
                      grid-template-columns: repeat(12, 1fr);
                      gap: var(--gutter);
                      width: 100%;

                      @media (max-width: 700px) {
                        margin: 0 var(--gutter);
                      }
                    `}
                  >
                    <div
                      css={css`
                        grid-column-start: 3;
                        grid-column-end: span 8;

                        @media (max-width: 700px) {
                          grid-column-start: 1;
                          grid-column-end: span 12;
                        }
                      `}
                    >
                      <div
                        css={css`
                          margin-bottom: var(--smallGutter);
                        `}
                      >
                        {formatDates(slide.startDate, slide.endDate)}<br />
                        <span css={css`text-transform: uppercase;`}>{slide.title}</span>
                      </div>
                      <div
                        css={css`
                          display: -webkit-box;
                          -webkit-box-orient: vertical;
                          -webkit-line-clamp: 5;
                          padding-bottom: 1rem;
                          overflow: hidden;

                          @media (max-width: 700px) {
                            padding-bottom: 0;
                          }
                        `}
                      >
                        {slide.people?.length > 0 && slide.people
                          .map(person => <span key={person.id}>{person.name}</span>)
                          .reduce((prev, curr) => [prev, ', ', curr])
                        }
                      </div>
                    </div>
                  </div>
                </div>}
                {slide.image && <div
                  css={css`
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    text-align: center;
                    white-space: pre-line;
                  `}
                >
                  <div
                    css={css`
                      display: grid;
                      grid-template-columns: repeat(12, 1fr);
                      gap: var(--gutter);
                      width: 100%;

                      @media (max-width: 700px) {
                        margin: 0 var(--gutter);
                      }
                    `}
                  >
                    <div
                      css={css`
                        grid-column-start: 3;
                        grid-column-end: span 8;

                        @media (max-width: 700px) {
                          grid-column-start: 1;
                          grid-column-end: span 12;
                        }
                      `}
                    >
                      {slide.text}
                    </div>
                  </div>
                </div>}
              </Link>
            )
          )}
        </div>
        <div
          css={css`
            position: absolute;
            top: calc(50% - var(--gutter));
            left: 0;
            font-family: 'ROM Condensed', sans-serif;
            font-weight: bold;
            font-size: 6rem;
            padding: var(--gutter);
            cursor: pointer;
            user-select: none;

            @media (max-width: 700px) {
              display: none;
            }

            &:hover {
              color: var(--pink)
            }
          `}
          onClick={() => embla?.scrollPrev() }
        >
          &larr;
        </div>
        <div
          css={css`
            position: absolute;
            top: calc(50% - var(--gutter));
            right: 0;
            font-family: 'ROM Condensed', sans-serif;
            font-weight: bold;
            font-size: 6rem;
            padding: var(--gutter);
            cursor: pointer;
            user-select: none;

            @media (max-width: 700px) {
              display: none;
            }

            &:hover {
              color: var(--pink)
            }
          `}
          onClick={() => embla?.scrollNext() }
        >
          &rarr;
        </div>
      </div>
      <div
        css={css`
          display: grid;
          grid-template-columns: repeat(12, 1fr);
          gap: var(--gutter);
          margin-top: 100vh;

          @media (max-width: 700px) {
            margin-top: calc(100vh - 55px);
          }
        `}
      >
        <div
          css={css`
            display: none;
            grid-column: span 12;
            text-align: center;

            @media (max-width: 700px) {
              display: block;
            }
          `}
        >
          {data.homepage.slides.length > 0 && data.homepage.slides
            .filter((slide, i) => (slide && (slide.featureImage || slide.image)))
            .map((slide, i) => (
              <div
                css={css`
                  display: inline-block;
                  width: 14px;
                  height: 14px;
                  background-color: ${(currentSlide === i) ? 'var(--darkBrown)' : 'var(--mutedBrown)'};
                  border-radius: 50%;
                  margin: 0 1.1rem;
                `}
                onClick={() => embla?.scrollTo(i) }
              >
              </div>
            ))
          }
        </div>
        <div
          className="type--large"
          css={css`
            grid-column-start: 2;
            grid-column-end: span 10;
            text-align: center;
            padding: 15rem 0;

            @media (max-width: 700px) {
              grid-column-start: 1;
              grid-column-end: span 12;
              padding: 7rem 0;
            }
          `}
        >
          <p>{data.homepage.introText}</p>
          <p>The gallery is currently {(isOpen(data.homepage.openingHours, data.homepage.closedDates).open) ? <span><span css={css`color: var(--green);`}>OPEN</span> until {isOpen(data.homepage.openingHours, data.homepage.closedDates).until}</span> : 'closed'}.</p>
        </div>
      </div>
      <div
        css={css`
          display: grid;
          grid-template-columns: repeat(12, 1fr);
          gap: var(--gutter);

          @media (min-width: 1440px) {
            .elaborate-three {
              display: grid;
              grid-template-columns: repeat(12, 1fr);
              gap: var(--gutter);
              grid-column: span 12;
              padding: var(--gutter) 0;
              border-top: 1px dotted var(--black);
              border-bottom: 1px dotted var(--black);

              > div {
                grid-row: 1;
                grid-column-start: 1;
                grid-column-end: span 5;

                &:nth-of-type(1) {
                  grid-column-start: 6;
                  grid-column-end: span 7;
                }
              }
            }
          }

          @media (max-width: 1440px) {
            .elaborate-two {
              display: grid;
              grid-template-columns: repeat(12, 1fr);
              gap: var(--gutter);
              grid-column: span 12;
              padding: var(--gutter) 0;
              border-top: 1px dotted var(--black);
              border-bottom: 1px dotted var(--black);

              > div {
                grid-row: 1;
                grid-column-start: 1;
                grid-column-end: span 5;

                &:nth-of-type(1) {
                  grid-column-start: 6;
                  grid-column-end: span 7;
                }
              }
            }
          }
        `}
      >
        {data.homepage.featuredProgram.length > 0 && data.homepage.featuredProgram.map((program, i) =>
          <React.Fragment key={i}>
            <ProgramCard
              className={`
                ${(i === 0 && data.homepage.featuredProgram.length % 3 !== 0) ? 'elaborate-three' : ''} 
                ${(i === 0 && data.homepage.featuredProgram.length % 2 !== 0) ? 'elaborate-two' : ''}
              `}
              css={css`
                grid-column: span 4;

                @media (max-width: 1439px) {
                  grid-column: span 6;
                }

                @media (max-width: 700px) {
                  grid-column: span 12;
                  margin-bottom: var(--gutter);
                }
              `}
              program={program}
              showImages={showImages}
            />
            {program.publications.length > 0 && program.publications
              .map((publication, i) => (
                <Link
                  key={i}
                  to={`/publication/${publication.slug?.current}`}
                  css={css`
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    grid-column: span 4;
                    text-align: center;
                    text-decoration: none;
                    white-space: pre-line;
                    padding: var(--gutter);
                    border: 1px solid var(--black);

                    &:hover {
                      color: var(--white);
                      background-color: var(--black);
                    }

                    @media (max-width: 1440px) {
                      grid-column: span 6;
                    }

                    @media (max-width: 700px) {
                      grid-column: span 12;
                      margin-bottom: var(--gutter);
                    }
                  `}
                >
                  {publication.title}<br />
                  {publication.author}<br /><br />
                  {publication.excerpt}
                </Link>
              ))
            }
          </React.Fragment>
        )}
      </div>
      <div
        css={css`
          margin: 10rem 0;
        `}
      >
        <div
          className="type--medium"
          css={css`
            text-align: center;

            & > a, & > div {
              &:hover {
                color: var(--purple);
                background-color: var(--white);
              }
            } 
          `}
        >
          <Link
            css={css`
              display: inline-block;
              color: var(--white);
              background-color: var(--purple);
              border: 1px solid var(--purple);
              border-radius: 10rem;
              padding: 5rem 15rem 5.5rem;
              text-decoration: none;
            `}
            to={data.homepage.buttonLink}
          >{data.homepage.buttonText}</Link>
        </div>
      </div>
      {data.homepage.ctaImage && <div
        className="type--large"
        css={css`
          position: relative;
          text-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
          overflow: hidden;
          margin: 10rem 0;

          @media (max-width: 700px) {
            font-size: 5rem;
          }

          a {
            color: var(--white);

            &:hover img {
              filter: grayscale(100%);
            }
          }
        `}
      >
        <Link
          to={data.homepage.ctaLink}
        >
          <GatsbyImage
            image={data.homepage.ctaImage.asset.gatsbyImageData}
            alt={data.homepage.ctaImage.asset.altText}
            loading="eager"
            css={css`
              width: 100%;
            `}
          />
          <div
            css={css`
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              left: 0;
              text-align: center;
              white-space: pre-line;
            `}
          >
            <div
              css={css`
                display: grid;
                grid-template-columns: repeat(12, 1fr);
                gap: var(--gutter);
                width: 100%;
              `}
            >
              <div
                css={css`
                  grid-column-start: 3;
                  grid-column-end: span 8;

                  @media (max-width: 700px) {
                    grid-column-start: 1;
                    grid-column-end: span 12;
                    padding: 0 var(--gutter);
                  }
                `}
              >
                {data.homepage.ctaText}
              </div>
            </div>
          </div>
        </Link>
      </div>}
      <div
        css={css`
          display: grid;
          grid-template-columns: repeat(12, 1fr);
          gap: var(--gutter);
          justify-content: center;
          margin-top: 6rem;
        `}
      >
        {data.opportunities?.nodes.length > 0 && data.opportunities.nodes
          .sort((a, b) => {
            if (a.endDate < DateTime.now().toISODate()) {
              return 1;
            } else {
              return -1;
            }
          })
          .filter((item, i) => item.endDate > DateTime.now().toISODate())
          .map((item, i) => (
            <Opportunity
              key={i}
              css={css`
                grid-column: ${(data.opportunities?.nodes.filter((item, i) => item.endDate > DateTime.now().toISODate()).length === 1) ? '5/span 4' : 'span 4'};

                @media (max-width: 900px) {
                  grid-column: span 6;
                }

                @media (max-width: 500px) {
                  grid-column: span 12;
                }
              `}
              title={item.title}
              slug={item.slug?.current}
              type={item.type}
              endDate={item.endDate}
              setEmbed={setEmbed}
            />
          )
        )}
      </div>
    </div>
  );
}

export const query = graphql`
  query {
    homepage: sanityHomepage(_id: {regex: "/(drafts\\\\.)?homepage/"}) {
     slides {
        ... on SanityProgram {
          _type
          slug {
            current
          }
          featureImage {
            ...Image
            asset {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
          startDate
          endDate
          title
          people {
            id
            name
          }
        }
        ... on SanitySlide {
          _type
          image {
            ...Image
            asset {
              gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
            }
          }
          text
          link
        }
      }
      subscribeText
      introText
      openingHours {
        day
        openTime
        closeTime
      }
      closedDates {
        startDate
        endDate
      }
      featuredProgram {
        id
        slug {
          current
        }
        featureImage {
          ...Image
          asset {
            title
            gatsbyImageData(sizes: "25vw", aspectRatio: 1.6, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        startDate
        endDate
        title
        people {
          id
          name
        }
        publications {
          slug {
            current
          }
          title
          author
          excerpt
        }
        location
        type {
          id
          title
          slug {
            current
          }
        }
        series {
          id
          title
          slug {
            current
          }
        }
      }
      buttonText
      buttonLink
      ctaText
      ctaImage {
        asset {
          gatsbyImageData
        }
      }
      ctaLink
    }
    opportunities: allSanityOpportunity(sort: {fields: endDate, order: ASC}) {
      nodes {
        title
        slug {
          current
        }
        endDate
        type
        _rawShortDescription(resolveReferences: {maxDepth: 10})
      }
    }
  }
`
